import React from 'react';
import { Parallax } from 'react-scroll-parallax';
// components
import SectionTrigger from 'src/components/SectionTrigger';
import VideoPlayer from 'src/components/VideoPlayer';
import ParallaxImage from 'src/components/parallax/ParallaxImage';
import { Arrow } from 'src/components/svgs'
import MobileHero from './PlaygroundHeroMobile';
import MobilePlaygroundScrollGallery from './PlaygroundScrollGalleryMobile';

function PlaygroundContentMobile({ images }) {
  const scrollOffset = [40, -80];
  // const scrollOffset = [0,0];
  const [
    pgMobileImg01,
    pgMobileImg02,
    pgMobileImg03,
    pgMobileImg04,
    pgMobileImg05,
    pgMobileImg06,
    pgMobileImg07,
    pgMobileImg08,
    pgMobileImg09,
    pgMobileImg10,
    pgMobileImg11,
    pgMobileImg12,
    pgMobileImg13,
    pgMobileImg14,
    pgMobileImg15,
    pgMobileImg16,
    pgMobileImg17,
    pgMobileImg18,
    pgMobileImg19,
    pgMobileImg20,
    pgMobileImg21,
    pgMobileImg22,
    pgMobileImg23,
    pgMobileImg24
  ] = images;
  const pgMobileVid02 =
    'https://player.vimeo.com/external/433463308.hd.mp4?s=c20bbf9081b6ede5c7951cbab04ea1eec117c8af&profile_id=174&oauth2_token_id=1365998561';
  const pgMobileVid03 =
    'https://player.vimeo.com/external/433463283.sd.mp4?s=b80f8c497d98a03c5b2a8659da7890d22ebc3318&profile_id=164&oauth2_token_id=1365998561';
  const pgMobileVid04 =
    'https://player.vimeo.com/external/433463319.hd.mp4?s=a228bba84c919f494127ca9e394ae1a07dd70e0b&profile_id=174&oauth2_token_id=1365998561';
  const pgMobileVid05 =
    'https://player.vimeo.com/external/433463330.sd.mp4?s=5d9d64c46bc08a83d49a4733a67e2ba49c22031e&profile_id=164&oauth2_token_id=1365998561';
  const pgMobileVid07 =
    'https://player.vimeo.com/external/433463337.sd.mp4?s=347e4600677c7eca0a5c81c4f2068a56cfcd461e&profile_id=139&oauth2_token_id=1365998561';
  const pgMobileVid08 =
    'https://player.vimeo.com/external/433463348.sd.mp4?s=fc5aa40d38cd8d07101b5266e5bf21d9c25c5430&profile_id=165&oauth2_token_id=1365998561';
  const pgMobileVid09 =
    'https://player.vimeo.com/external/433463359.sd.mp4?s=6f086c06ab36866668a5ea35bf4f69c696d7d43d&profile_id=165&oauth2_token_id=1365998561';
  const pgMobileVid17 =
    'https://player.vimeo.com/external/433463298.sd.mp4?s=ef42686c3b56c21ca528f098813fbb9e8aa584ca&profile_id=165&oauth2_token_id=1365998561';
  return (
    <div className="pg-mobile">
      <SectionTrigger bgColor="#507d9b">
        <MobileHero images={[pgMobileImg01]} />
      </SectionTrigger>
      <div className="pg-mobile__container">
        <Parallax className="parallax pg-mobile__heading pg-mobile__heading--1" y={scrollOffset}>
          <h2 className="challenge">Challenge</h2>
        </Parallax>
        <p className="pg-mobile__copy pg-mobile__copy--1">
          How does the most iconic Basketball brand on the planet
          promote 4 distinct categories with a massive volume of diverse
          products without alienating their core basketball audience?
        </p>
        <VideoPlayer
          className="pg-mobile__video pg-mobile__video--1"
          src={pgMobileVid02}
          poster={pgMobileImg02}
        />
        <VideoPlayer
          className="pg-mobile__video pg-mobile__video--2"
          src={pgMobileVid03}
          poster={pgMobileImg03}
        />
        <Parallax className="parallax pg-mobile__heading pg-mobile__heading--2" y={scrollOffset}>
          <h2 className="solution">Solution</h2>
        </Parallax>
        <VideoPlayer
          className="pg-mobile__video pg-mobile__video--3"
          src={pgMobileVid04}
          poster={pgMobileImg04}
        />
        <p className="pg-mobile__copy pg-mobile__copy--2">
          We created a visual "love letter" to the Playground - engaging each of our unique audiences through the game &amp; the culture.
        </p>
        <VideoPlayer
          className="pg-mobile__video pg-mobile__video--4"
          src={pgMobileVid05}
          poster={pgMobileImg05}
        />
        <Parallax className="pg-mobile__img pg-mobile__img--2" y={scrollOffset}>
          <ParallaxImage src={pgMobileImg06} alt="" />
        </Parallax>
        <blockquote className="pg-mobile__blockquote pg-mobile__blockquote--1 ">
          &ldquo;Blake Griffin pays tribute to the playground basketball court in a stylish jordan spot&rdquo;
          <cite>Ad Age</cite>
        </blockquote>
        <p className="pg-mobile__tagline pg-mobile__tagline--1">
          IG Stories
        </p>
        <VideoPlayer
          className="pg-mobile__video pg-mobile__video--5"
          src={pgMobileVid07}
          poster={pgMobileImg07}
        />
        <VideoPlayer
          className="pg-mobile__video pg-mobile__video--6"
          src={pgMobileVid08}
          poster={pgMobileImg08}
        />
        <VideoPlayer
          className="pg-mobile__video pg-mobile__video--7"
          src={pgMobileVid09}
          poster={pgMobileImg09}
        />
        <p className="pg-mobile__tagline pg-mobile__tagline--2">
          Dot Com
        </p>
        <img src={pgMobileImg10} className="pg-mobile__img pg-mobile__img--3" />
        <img src={pgMobileImg11} className="global-box-shadow pg-mobile__img pg-mobile__img--4" />
        <img src={pgMobileImg12} className="global-box-shadow pg-mobile__img pg-mobile__img--5" />
        <p className="pg-mobile__tagline pg-mobile__tagline--3">
          Lookbook
        </p>
        <MobilePlaygroundScrollGallery images={[pgMobileImg13, pgMobileImg14, pgMobileImg15, pgMobileImg16, pgMobileImg17, pgMobileImg18]} />
        <Arrow modifier="pg-mobile__scroll-gallery-arrow" />
        <blockquote className="pg-mobile__blockquote pg-mobile__blockquote--2">
          &ldquo;Jordan Brand salutes the magic of the playground court in lovely, nostalgic summer&rdquo;
          <cite>Adweek</cite>
        </blockquote>
        <Parallax className="parallax pg-mobile__heading pg-mobile__heading--3" y={scrollOffset}>
          <h2 className="execution">Execution</h2>
          <h2 className="execution">Execution</h2>
        </Parallax>
        <img src={pgMobileImg19} className="pg-mobile__img pg-mobile__img--12" />
        <VideoPlayer
          className="pg-mobile__video pg-mobile__video--8"
          src={pgMobileVid17}
          poster={pgMobileImg20}
        />
        <img src={pgMobileImg21} className="pg-mobile__img pg-mobile__img--13" />
        <p className="pg-mobile__copy pg-mobile__copy--3">
          Anchored by Blake Griffin, an all-star team featuring our founder, Dexton Deboree who wrote & directed the campaign, Academy Award nominated DP James Laxton shot on 16mm Bolex, Polaroid,BET-ACAM, Alexa, Alexa Mini, and iPhone cameras, and award-winning production designer Alexandra Schaller brought the set to life.
        </p>
        <Parallax className="parallax pg-mobile__heading pg-mobile__heading--4" y={scrollOffset}>
          <h2 className="impact">Impact</h2>
          <h2 className="impact">Impact</h2>
        </Parallax>
        <img src={pgMobileImg22} className="global-box-shadow pg-mobile__img pg-mobile__img--14" />
        <img src={pgMobileImg23} className="global-box-shadow pg-mobile__img pg-mobile__img--15" />
        <Parallax className="pg-mobile__blockquote pg-mobile__blockquote--3" y={scrollOffset}>
          <blockquote>
            Over 26m impressions. 1.8m engagements. And sales stats that exceeded KPI's.
          </blockquote>
        </Parallax>
        <img src={pgMobileImg24} className="global-box-shadow pg-mobile__img pg-mobile__img--16" />
      </div>
      <SectionTrigger
        bgColor="#507d9b"
        className="pg-mobile__section pg-mobile__section--1"
        textColor="#000"
      />
      <SectionTrigger
        bgColor="#e1b9af"
        className="pg-mobile__section pg-mobile__section--2"
        textColor="#fff"
      />
      <SectionTrigger
        bgColor="#a4272a"
        className="pg-mobile__section pg-mobile__section--3"
        textColor="#000"
      />
      <SectionTrigger
        bgColor="#f0f0f0"
        className="pg-mobile__section pg-mobile__section--4"
        textColor="#000"
      />
    </div>
  )
}

export default PlaygroundContentMobile;