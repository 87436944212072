import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ImageGallery from '../ImageGallery';

function ThePlaygroundSlideShow() {
  const data = useStaticQuery(graphql`
    query thePlaygroundSlideShow {
      allCloudinaryMedia(
        filter: { tags: { eq: "the-playground-slide-gallery" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            secure_url
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const images = [];
  cloudMedia.map(image => images.push(image.node.secure_url));
  return <ImageGallery images={[images]} />;
}

export default ThePlaygroundSlideShow;
