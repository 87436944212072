import React from 'react';

function PlaygroundScrollGalleryMobile({ images }) {
  const [
    pgMobileImg13,
    pgMobileImg14,
    pgMobileImg15,
    pgMobileImg16,
    pgMobileImg17,
    pgMobileImg18,
  ] = images;
  return (
    <div className="pg-mobile__scroll-gallery">
      <div className="pg-mobile__scroll-gallery-wrapper">
        <img src={pgMobileImg13} className="pg-mobile__img pg-mobile__img--6" />
        <img src={pgMobileImg14} className="pg-mobile__img pg-mobile__img--7" />
        <img src={pgMobileImg15} className="pg-mobile__img pg-mobile__img--8" />
        <img src={pgMobileImg16} className="pg-mobile__img pg-mobile__img--9" />
        <img src={pgMobileImg17} className="pg-mobile__img pg-mobile__img--10" />
        <img src={pgMobileImg18} className="pg-mobile__img pg-mobile__img--11" />
      </div>
    </div>
  )
}

export default PlaygroundScrollGalleryMobile;