import React from 'react';
import { Parallax } from 'react-scroll-parallax';
// components
import * as Styled from 'src/components/the-playground/ThePlaygroundStyled';
import SlideShow from 'src/components/the-playground/ThePlaygroundSlideShow';
import ParallaxVideo from 'src/components/parallax/ParallaxVideo';
import ParallaxImage from 'src/components/parallax/ParallaxImage';
import ZoomVideo from 'src/components/ZoomVideo';
import VideoPlayer from 'src/components/VideoPlayer';
import SectionTrigger from 'src/components/SectionTrigger';
import CaseStudyNav from 'src/components/CaseStudyFooterNav';
import { JumpmanIcon } from 'src/components/svgs';

function PlaygroundContent({ images }) {
  const [
    jordanHat1,
    rectangle6,
    web10and11,
    fullCap12,
    igPost13,
    noTang16,
    noTang18,
    jumpmanIg19,
    blakeTweet20,
    nikeTweet21,
    riseChicago22,
    braids18,
  ] = images;
  // const [
  //   playgroundVid2,
  //   wutangVid3,
  //   playgroundVid4,
  //   socialComplexVid5,
  //   socialComplexVid7,
  //   socialComplexVid8,
  //   socialComplexVid9,
  //   playgroundVid17,
  // ] = videos;
  const playgroundVid2 =
    'https://player.vimeo.com/external/433463308.hd.mp4?s=c20bbf9081b6ede5c7951cbab04ea1eec117c8af&profile_id=174&oauth2_token_id=1365998561';
  const wutangVid3 =
    'https://player.vimeo.com/external/433463283.sd.mp4?s=b80f8c497d98a03c5b2a8659da7890d22ebc3318&profile_id=164&oauth2_token_id=1365998561';
  const playgroundVid4 =
    'https://player.vimeo.com/external/433463319.hd.mp4?s=a228bba84c919f494127ca9e394ae1a07dd70e0b&profile_id=174&oauth2_token_id=1365998561';
  const socialComplexVid5 =
    'https://player.vimeo.com/external/433463330.sd.mp4?s=5d9d64c46bc08a83d49a4733a67e2ba49c22031e&profile_id=164&oauth2_token_id=1365998561';
  const socialComplexVid7 =
    'https://player.vimeo.com/external/433463337.sd.mp4?s=347e4600677c7eca0a5c81c4f2068a56cfcd461e&profile_id=139&oauth2_token_id=1365998561';
  const socialComplexVid8 =
    'https://player.vimeo.com/external/433463348.sd.mp4?s=fc5aa40d38cd8d07101b5266e5bf21d9c25c5430&profile_id=165&oauth2_token_id=1365998561';
  const socialComplexVid9 =
    'https://player.vimeo.com/external/433463359.sd.mp4?s=6f086c06ab36866668a5ea35bf4f69c696d7d43d&profile_id=165&oauth2_token_id=1365998561';
  const playgroundVid17 =
    'https://player.vimeo.com/external/433463298.sd.mp4?s=ef42686c3b56c21ca528f098813fbb9e8aa584ca&profile_id=165&oauth2_token_id=1365998561';

  return (
    <div className="playground">
      <SectionTrigger bgColor="#507d9b" textColor="#000">
        <div className="grid__container">
          <div className="grid" style={{ paddingBottom: '10%' }}>
            <Parallax
              className="playground__title playground__title--1"
              y={[0, -100]}
            >
              <JumpmanIcon modifier="the-playground__icon--jumpman" />
              <h1 className="main-heading">The Playground</h1>
              <p>An ode to the game & the culture</p>
            </Parallax>

            <div className="playground__img playground__img--1">
              <img src={jordanHat1} background="#507d9b" alt="jordan hat" />
            </div>

            <Parallax
              className="playground__video playground__video--1"
              y={[-10, -20]}
            >
              <ParallaxVideo autoPlayVideo={true} src={playgroundVid2} muted />
            </Parallax>

            <Parallax
              className="playground__subtitle playground__subtitle--1"
              y={[-150, 50]}
            >
              <h2 className="main-heading">Challenge</h2>
            </Parallax>
            <Parallax
              className="playground__blurb playground__blurb--1"
              y={[100, -50]}
            >
              <p>
                How does the most iconic Basketball brand on the planet
                promote 4 distinct categories with a massive volume of diverse
                products without alienating their core basketball audience?
              </p>
            </Parallax>
          </div>
        </div>
      </SectionTrigger>

      <SectionTrigger bgColor="#507d9b" textColor="#000">
        <ZoomVideo autoPlayVideo={true} src={wutangVid3} />
      </SectionTrigger>

      <SectionTrigger bgColor="#e1b9af" textColor="#fff">
        <div className="grid__container">
          <div className="grid playground-2">
            <div className="playground-2__subtitle">
              <h2 className="main-heading">Solution</h2>
            </div>
            <Parallax
              className="playground-2__vid playground-2__vid--1"
              y={[20, -40]}
            >
              <ParallaxVideo src={playgroundVid4} />
            </Parallax>
            <div className="playground-2__blurb">
              <p>
                We created a visual "love letter" to the Playground - engaging
                each of our unique audiences through the game &amp; the
                culture.
              </p>
            </div>
            <div className="video-wrapper playground-2__vid playground-2__vid--2">
              <VideoPlayer muted src={socialComplexVid5} />
            </div>
            <div className="playground-2__img">
              <img src={rectangle6} alt="rectangle" />
            </div>
            <Parallax className="playground-2__quote" y={[20, -80]}>
              <blockquote>
                <p>
                  &ldquo;Blake Griffin
                  <br />
                  pays tribute to the
                  <br />
                  playground basketball
                  <br />
                  court in a stylish
                  <br />
                  jordan spot&rdquo;
                </p>
                <cite>Ad Age</cite>
              </blockquote>
            </Parallax>
          </div>
          <Styled.Section2Blurb2>
            <p>IG Stories</p>
          </Styled.Section2Blurb2>
          <div className="grid" style={{ paddingBottom: '10%' }}>
            <Styled.Section2VidCol1>
              <ParallaxVideo src={socialComplexVid7} />
            </Styled.Section2VidCol1>
            <Styled.Section2VidCol2>
              <ParallaxVideo src={socialComplexVid8} />
            </Styled.Section2VidCol2>
            <Styled.Section2VidCol3>
              <ParallaxVideo src={socialComplexVid9} />
            </Styled.Section2VidCol3>
          </div>
        </div>
      </SectionTrigger>

      <SectionTrigger
        className="playground__section playground__section--3"
        bgColor="#a4272a"
        textColor="#fff"
      >
        <Styled.Section3Blurb1 y={[0, -50]}>
          <p>Dot Com</p>
        </Styled.Section3Blurb1>
        <div className="panel">
          <img src={web10and11} alt="web" />
        </div>
        <div className="grid__container">
          <div
            className="grid"
            style={{ paddingBottom: '10%', paddingTop: '10%' }}
          >
            <Styled.Section3Img1 y={[-20, 20]}>
              <ParallaxImage
                src={fullCap12}
                className="global-box-shadow"
                alt="full cap"
              />
            </Styled.Section3Img1>
            <Styled.Section3Img2 y={[-50, 20]}>
              <ParallaxImage
                src={igPost13}
                className="global-box-shadow"
                alt="instagram post"
              />
            </Styled.Section3Img2>
          </div>
        </div>
      </SectionTrigger>
      <SectionTrigger
        bgColor="#a4272a"
        textColor="#fff"
        style={{ position: 'relative' }}
      >
        <div className="grid__container">
          <p className="gallery-title">Lookbook</p>
        </div>
        <SlideShow />
        <div className="container container--small">
          <span className="scroll-arrow scroll-arrow--right" />
        </div>
      </SectionTrigger>
      <SectionTrigger bgColor="#f1e1c5" textColor="#000">
        <div className="grid__container">
          <Styled.Section4Quote y={[0, 20]}>
            <blockquote>
              <p>
                &ldquo;Jordan Brand
                <br />
                salutes the magic of
                <br />
                the playground court
                <br />
                in lovely, nostalgic
                <br />
                summer ad&rdquo;
              </p>
            </blockquote>
            <p>Adweek</p>
          </Styled.Section4Quote>
          <div
            className="grid"
            style={{
              paddingBottom: '20%',
              paddingTop: '20%',
            }}
          >
            <Styled.Section4SubTitle y={[-60, 0]}>
              <h2 className="main-heading">Execution</h2>
            </Styled.Section4SubTitle>
            <Styled.Section4Blurb1 y={[0, 50]}>
              <p>
                Anchored by Blake Griffin, an all-star team featuring our
                founder, Dexton Deboree who wrote & directed the campaign,
                Academy Award nominated DP James Laxton shot on 16mm Bolex,
                Polaroid, BET-ACAM, Alexa, Alexa Mini, and iPhone cameras, and
                award-winning production designer Alexandra Schaller brought
                this set to life.
              </p>
            </Styled.Section4Blurb1>

            <Styled.Section4Img1 y={[-20, 20]}>
              <ParallaxImage src={noTang16} alt="no tang" />
            </Styled.Section4Img1>
            <Styled.Section4Vid1 y={[10, 20]}>
              <ParallaxVideo src={playgroundVid17} />
            </Styled.Section4Vid1>
            <Styled.Section4Img2 y={[20, 20]}>
              <ParallaxImage src={noTang18} alt="no tang" />
            </Styled.Section4Img2>
          </div>
        </div>
      </SectionTrigger>
      <SectionTrigger bgColor="#f0f0f0" textColor="#000">
        <div className="grid__container">
          <div
            className="grid"
            style={{ paddingBottom: '20%', paddingTop: '20%' }}
          >
            <Styled.Section5SubTitle y={[25, -100]}>
              <h2 className="main-heading">Impact</h2>
            </Styled.Section5SubTitle>
            <Styled.Section5Img1 y={[20, -20]}>
              <ParallaxImage
                src={jumpmanIg19}
                className="global-box-shadow"
                alt="jordan brand instagram"
              />
            </Styled.Section5Img1>
            <Styled.Section5Img2 y={[-40, -40]}>
              <ParallaxImage
                src={blakeTweet20}
                className="global-box-shadow"
                alt="blake griffin tweet"
              />
            </Styled.Section5Img2>
            <Styled.Section5Quote y={[100, -20]}>
              <blockquote>
                <p>
                  Over 26m impressions.
                  <br />
                  1.8m engagements and
                  <br />
                  sales stats that
                  <br />
                  exceeded kpi&rsquo;s.
                </p>
              </blockquote>
            </Styled.Section5Quote>
            <Styled.Section5Img3 y={[40, -30]}>
              <ParallaxImage
                src={nikeTweet21}
                className="global-box-shadow"
                alt="nike tweet"
              />
            </Styled.Section5Img3>
          </div>
        </div>
      </SectionTrigger>
      {/* Each NavCase component needs the following... */}
      <CaseStudyNav
        nextTitle="&ldquo;The Ones&rdquo;"
        nextImg={braids18}
        nextLink="the-ones"
        prevTitle="Rise Chicago"
        prevImg={riseChicago22}
        prevLink="rise-chicago"
      />
    </div>
  );
}

export default PlaygroundContent;