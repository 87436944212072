import React from 'react';
// components
import { JumpmanIcon } from 'src/components/svgs';

function PlaygroundHeroMobile({ images }) {
  const [pgMobileImg01] = images;
  return (
    <div className="pg-mobile__hero">
      <div className="pg-mobile__hero-container">
        <div className="pg-mobile__hero-header">
          <JumpmanIcon modifier="pg-mobile__hero-logo" />
          <h1 className="pg-mobile__hero-heading">The Playground</h1>
          <p className="pg-mobile__hero-subheading">
            An ode to the game & the culture
          </p>
        </div>
      </div>
      <img className="pg-mobile__img pg-mobile__img--1" src={pgMobileImg01} />
    </div>
  )
}

export default PlaygroundHeroMobile;