import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
// utils
import { above } from '../../utils';

export const Section2Blurb2 = styled(Parallax)`
  grid-area: 1 / 1 / auto / 4;
  z-index: 10;
  margin-top: 10%;

  p {
    font-size: ${(16 / 1600) * 100}vw;
    line-height: 1;
    letter-spacing: 0.1em;
    font-weight: 900;
    text-transform: uppercase;
    font-style: normal;
  }
`;

export const Section2VidCol1 = styled(Parallax)`
  grid-area: 4 / 1 / auto / 5;
  z-index: 20;

  video {
    width: 100%;
  }
`;
export const Section2VidCol2 = styled(Parallax)`
  grid-area: 4 / 5 / auto / 9;
  z-index: 20;

  video {
    width: 100%;
  }
`;
export const Section2VidCol3 = styled(Parallax)`
  grid-area: 4 / 9 / auto / 13;
  z-index: 20;

  video {
    width: 100%;
  }
`;

export const Section3Blurb1 = styled(Parallax)`
  z-index: 20;
  margin-left: 14%;
  p {
    font-size: ${(16 / 1600) * 100}vw;
    line-height: 1;
    letter-spacing: 0.1em;
    font-weight: 900;
    text-transform: uppercase;
    font-style: normal;

    // ${above.med2`
      font-size: 20px;
    // `}
  }
`;

export const Section3Img1 = styled(Parallax)`
  grid-area: 1 / 2 / auto / 8;
  z-index: 10;
`;

export const Section3Img2 = styled(Parallax)`
  grid-area: 2 / 9 / auto / 13;
  z-index: 10;
`;

export const Section4Quote = styled(Parallax)`
  grid-area: 1 / 1 / auto / 13;
  z-index: 20;
  padding-top: 20%;
  padding-bottom: 20%;
  p {
    margin: 0;
    text-transform: uppercase;
    text-align: center;

    // ${above.med2`
    //   font-size: 80px;
    // `}
  }
`;

export const Section4SubTitle = styled(Parallax)`
  grid-area: 2 / 1 / auto / 13;
  z-index: 1;
  text-align: center;
  h2 {
    margin: 0;
    white-space: nowrap;
    &::before,
    &::after {
      content: 'Execution';
      display: block;
    }
  }
`;

export const Section4Blurb1 = styled(Parallax)`
  grid-area: 2 / 9 / auto / 13;
  z-index: 20;
  p {
    margin: 0;
    // font-size: 20px;
  }
`;
export const Section4Img1 = styled(Parallax)`
    grid-area: 2 / 1 / auto / 7;
    z-index: 3;
  }
`;
export const Section4Vid1 = styled(Parallax)`
  grid-area: 3 / 4 / auto / 10;
  z-index: 10;
  video {
    width: 100%;
  }
`;
export const Section4Img2 = styled(Parallax)`
  grid-area: 5 / 7 / auto / 13;
  z-index: 10;
`;

export const Section5SubTitle = styled(Parallax)`
  grid-area: 1 / 5 / auto / 9;
  z-index: 1;
  text-align: center;
  align-self: self-end;
  h2 {
    margin: 0;
    &::after {
      content: 'Impact Impact Impact';
      display: block;
    }
  }
`;

export const Section5Img1 = styled(Parallax)`
  grid-area: 1 / 1 / auto / 5;
  z-index: 10;
`;
export const Section5Img2 = styled(Parallax)`
  grid-area: 2 / 9 / auto / 13;
  z-index: 20;
`;

export const Section5Quote = styled(Parallax)`
  grid-area: 2 / 1 / auto / 13;
  z-index: 10;
`;
export const Section5Img3 = styled(Parallax)`
  grid-area: 3 / 4 / auto / 9;
  z-index: 20;
`;
