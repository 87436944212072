import React from 'react';
import { ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import 'src/scss/the-playground.scss';
// hooks
import playgroundMedia from 'src/hooks/playgroundMedia'
import playgroundMobileMedia from 'src/hooks/playgroundMobileMedia';
import useMediaQuery from 'src/hooks/useMediaQuery';
// lib
import handlePageTransition from 'src/lib/handle-page-transition';
// components
import SEO from 'src/components/SEO';
import PlaygroundContent from 'src/components/the-playground/PlaygroundContent';
import MobilePlaygroundContent from 'src/components/the-playground/PlaygroundContentMobile';

function ThePlaygroundPage({ transitionStatus }) {
  let isDesktop = useMediaQuery('(min-width: 1024px)');
  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  const mobileImages = [];
  const images = [];
  const mobileMedia = playgroundMobileMedia();
  const media = playgroundMedia();

  mobileMedia.allCloudinaryMedia.edges.forEach(item => mobileImages.push(item.node.secure_url));
  media.allCloudinaryMedia.edges.forEach(item => images.push(item.node.secure_url));

  return (
    <div>
      <SEO title="The Playground | Falkon Content" />
      <div className="pg">
        {isDesktop
          ? (<PlaygroundContent images={images} />)
          : (<MobilePlaygroundContent images={mobileImages} />)
        }
      </div>
    </div>
  )
}

export default React.memo(ThePlaygroundPage);